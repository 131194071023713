import * as React from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import BottomCommonSection from "../components/BottomCommonSection";
import { getIsVertical } from "../utils/getIsVertical";

import "../styles/paliativas-aprupes-klinika.css";

export default function PalliativeCareClinic() {
	const { t } = useTranslation();
	const isVertical = getIsVertical();

	return (
		<Layout pageId={PageId.PalliativeCareClinic}>
			<Seo
				title={t("seoTitle")}
				description={t("seoDescription")}
			/>
			<div className="contentBlock topTextSection">
				<h1>{t("topText")}</h1>
			</div>
			<div className="contentBlock" style={isVertical ? { padding: "8rem 0" } : {}}>
				<div className="palliativeDescription">
					<p><Trans i18nKey="whatsPalliative" components={{ break: isVertical ? <></> : <br /> }} /></p>
					<div className="palliativeDescriptionTextBlock first">
						<p>{t("palliativeDescription1")}</p>
						<p>{t("palliativeDescription2")}</p>
					</div>
				</div>
				<div className="palliativeDescription">
					<p><Trans i18nKey="whatsHospice" components={{ break: isVertical ? <></> : <br /> }} /></p>
					<div className="palliativeDescriptionTextBlock">
						<p>{t("hospiceDescription")}</p>
					</div>
				</div>
			</div>
			<div className="contentBlock whatsOffered">
				<p>{t("whatsOffered")}</p>
				<div className="offers">
					<div>{t("offer1")}</div>
					<div>{t("offer2")}</div>
					<div><Trans i18nKey="offer3" components={{ break: isVertical ? <></> : <br /> }} /></div>
					<div>{t("offer4")}</div>
					<div>{t("offer5")}</div>
				</div>
				<p className="pricesTitle">{t("offerPrices")}</p>
				<div className="palliativePrices">
					<div>
						<div className="palliativePrice">
							<h2>52<span>€</span></h2>
							<h2>{t("perDay")}</h2>
						</div>
						<p>{t("tripleRoom")}</p>
					</div>
					<div>
						<div className="palliativePrice">
							<h2>61<span>€</span></h2>
							<h2>{t("perDay")}</h2>
						</div>
						<p>{t("doubleRoom")}</p>
					</div>
					<div>
						<div className="palliativePrice">
							<h2>78<span>€</span></h2>
							<h2>{t("perDay")}</h2>
						</div>
						<p>{t("singleRoom")}</p>
					</div>
				</div>
			</div>
			<BottomCommonSection />
		</Layout>
	);
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["paliativas-aprupes-klinika", "common", "BottomCommonSection"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
